<template>
  <div>
    <b-modal
      v-model="model_setect"
      id="modal-center"
      centered
      title="เลือกประเภทการ์ด"
      ok-only
      ok-variant="danger"
      ok-title="ยกเลิก"
      no-close-on-backdrop
    >
      <b-row>
        <b-col cols="4">
          <div id="card1">
            <b-card
              :img-src="require('@/assets/images/card_message/1.png')"
              style="margin-bottom: 0.2em"
            >
              <b-form-radio value="1" v-model="Selected"> สินค้า </b-form-radio>
            </b-card>
          </div>
        </b-col>
        <b-col cols="4">
          <div id="card2">
            <b-card
              :img-src="require('@/assets/images/card_message/2.png')"
              style="margin-bottom: 0.2em"
            >
              <b-form-radio value="2" v-model="Selected"> บุคคล </b-form-radio>
            </b-card>
          </div>
        </b-col>
        <b-col cols="4">
          <div id="card3" style="border: 2px solid blue; border-radius: 6px">
            <b-card
              :img-src="require('@/assets/images/card_message/3.png')"
              style="margin-bottom: 0.2em"
            >
              <b-form-radio value="3" v-model="Selected"> รูปภาพ </b-form-radio>
            </b-card>
          </div>
        </b-col>
        <div align="center" style="margin-left: 43%">
          <br />
          <b-button variant="relief-primary" @click="open_model()">
            เลือก
          </b-button>
        </div>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BCardCode,
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BCardText,
  BCardTitle,
  BCard,
  BFormFile,
  BFormRadio,
  BFormInput,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";



export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BRow,
    BCol,
    BFormTextarea,
    BCardText,
    BCardTitle,
    BCard,
    BFormFile,
    BFormRadio,
    BFormInput,
    BAvatar,
    BBadge,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      
      Selected: "3",
      model_setect: false,

    };
  },
  mounted() {},
  computed: {},
  watch: {
    Selected: function (val) {
      if (val === "1") {
        document.getElementById("card1").style.border = "2px solid blue";
        document.getElementById("card2").style.border = "";
        document.getElementById("card3").style.border = "";
      }
      if (val === "2") {
        document.getElementById("card1").style.border = "";
        document.getElementById("card2").style.border = "2px solid blue";
        document.getElementById("card3").style.border = "";
      }
      if (val === "3") {
        document.getElementById("card1").style.border = "";
        document.getElementById("card2").style.border = "";
        document.getElementById("card3").style.border = "2px solid blue";
      }
    },
  },
  methods: {
 
    showmodeladdSP() {
      this.model_addsp = false;
      this.model_setect = true;
    },
    open_model() {
      this.$router.push({
        path: "/create/MessageCard",
        query: { id: this.Selected },
      });


    },


  },
};
</script>
